const testimonials = [
  {
    type: 'student',
    classElement: 'odd',
    description:
      'My counselor was super professional and excellent in terms of everything. She was listening to me and she was so compassionate. I was having hard time judging by my racial profile and she taught me how to embrace the uncontrollable situation and how to cope with this well.',
  },
  {
    type: 'student',
    classElement: 'odd',
    description:
      'I don&rsquo;t think it even took a week from my online form to sitting in front of a counselor. The counselor was amazing and we did a quick work on my main problems and would feel very comfortable going back if anything else arises.',
  },
  {
    type: 'student',
    classElement: 'odd',
    description:
      'I was reluctant to try this service, but, was very relieved when I did. I had an excellent experience.',
  },
  {
    type: 'student',
    classElement: 'odd',
    description:
      'My counsellor was excellent during a time of uncertainty, she provided a soundboard when I needed it most and was eceptive to my situation. She provided clarity and created a positive impact.',
  },
  {
    type: 'student',
    classElement: 'odd',
    description:
      'My counselor was incredibly attentive and responsive, even over the phone. Having someone who remembered my circumstances and story from session to session was great, and I feel very lucky to have been matched with the person I was.',
  },
  {
    type: 'school',
    classElement: 'even',
    description:
      'The team is prompt, empathetic, and professional in all of their correspondence with our staff and students. The wide range of services offered continues to be an excellent additional layer of support to our student population.',
  },
  {
    type: 'school',
    classElement: 'even',
    description:
      'The mental health support line is a vitally important element of our care plan for students’ health and well-being. The confidential, multilingual, 24/7 assistance has helped so many of our students in times of need, whether large or small, and to some this service may have even been life-saving.',
  },
];

module.exports = testimonials;
