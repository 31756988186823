import React, { useState } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ShutterstockPhotoTestimonials from '../images/uploads/headers/shutterstock_1633507663.jpg';
import testimonials from '../content/testimonials';

const Testimonials = () => {
  const [testimonialType, toggleTestimonialType] = useState('all');

  /**
   *
   * Return an array of filtered testimonials
   *
   * @param {string} type - school | student | all
   * @param {string} testimonialArray
   * @returns {Array}
   */
  const filteredTestimonials = (type, testimonialArray) => {
    if (type === 'all') {
      return testimonialArray;
    }

    return testimonialArray.filter((item) => {
      return item.type === type;
    });
  };

  return (
    <Layout pageSlug="testimonials">
      <SEO title="Testimonials" />
      <div className="row bg-grey bg-marque">
        <section className="row header">
          <div className="container">
            <div className="row content">
              <div className="col l24 m24 s24">
                <div className="box bs header-full-image">
                  <div className="page-heading">
                    <h3>Testimonials</h3>
                  </div>
                  <div className="inner-margin-top-minus-60">
                    {
                      <img
                        src={ShutterstockPhotoTestimonials}
                        alt="Testimonials"
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="row">
          <div className="container">
            <div className="row">
              <div className="col l16 m16 s24">
                <div className="box bs">
                  <h2>
                    Don’t just take
                    <br />
                    our word for it.
                  </h2>
                </div>
              </div>
            </div>
            <div className="row padding-bottom-60">
              <div className="col l16 m16 s24 offset-l2 offset-m2 offset-s0">
                <div className="box bs">
                  <div className="testimonial-toggle">
                    <ul>
                      <li
                        id="student"
                        className={`${
                          testimonialType === 'student' ||
                          testimonialType === 'all'
                            ? 'active'
                            : null
                        }`}
                        onClick={() => toggleTestimonialType('student')}
                      >
                        Students
                      </li>
                      <li
                        id="school"
                        className={`${
                          testimonialType === 'school' ||
                          testimonialType === 'all'
                            ? 'active'
                            : null
                        }`}
                        onClick={() => toggleTestimonialType('school')}
                      >
                        Schools
                      </li>
                    </ul>
                  </div>

                  {filteredTestimonials(testimonialType, testimonials).map(
                    (item, key) => (
                      <div
                        className={`speech-bubble ${item.type} ${item.classElement} active`}
                        key={key}
                      >
                        <p>{item.description}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="row bg-purple padding-top-40 padding-bottom-60">
          <div className="container">
            <div className="row">
              <div className="col l24 m24 s24">
                <div className="box bs">
                  <h3>How can we help?</h3>
                  <div className="btn-inline">
                    <Link
                      className="btn btn-primary animated-arrow"
                      to="/about-us"
                    >
                      <span className="the-arrow -left">
                        <span className="shaft"></span>
                      </span>
                      <span className="main">
                        <span className="text"> About Us </span>
                        <span className="the-arrow -right">
                          <span className="shaft"></span>
                        </span>
                      </span>
                    </Link>
                    <Link
                      className="btn btn-primary animated-arrow"
                      to="/schools-universities"
                    >
                      <span className="the-arrow -left">
                        <span className="shaft"></span>
                      </span>
                      <span className="main">
                        <span className="text">
                          {' '}
                          Schools &amp; Universities{' '}
                        </span>
                        <span className="the-arrow -right">
                          <span className="shaft"></span>
                        </span>
                      </span>
                    </Link>
                    <Link
                      className="btn btn-primary animated-arrow"
                      to="/international-students"
                    >
                      <span className="the-arrow -left">
                        <span className="shaft"></span>
                      </span>
                      <span className="main">
                        <span className="text"> International Students </span>
                        <span className="the-arrow -right">
                          <span className="shaft"></span>
                        </span>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Testimonials;
